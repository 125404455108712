import React from "react";
import './Skills.css';
import cricket from '../assests/cricket.png';
import video3 from "../assests/allrounder.mp4";
import devagan from '../assests/devgan.mp4';
import ammanahi from '../assests/8w5p8n.jpg';
import VideoRender from "./VideoPlayer";
const SkillSet = ({ currentPlaying, onVideoPlay }) => {
    return (
        <section id="About2">
            <div className="skilldiv">
                <span>Every evening after school me getting prepped for cricket match
                </span>
                <div className="AboutTextAndImg">
                    <img src={cricket} alt="" className="AboutImg1" />
                </div>
                <span>Skills I have in cricket</span>
                <VideoRender 
                    url={video3} 
                    isPlaying={currentPlaying === 'video3'} 
                    onPlay={() => onVideoPlay('video3')} 
                />
                <span style={{ marginTop: '2rem' }}>How I am revered in cricketing fraternity</span>
                <VideoRender 
                    url={devagan} 
                    isPlaying={currentPlaying === 'devagan'} 
                    onPlay={() => onVideoPlay('devagan')} 
                />
                <span style={{ marginTop: '2rem' }}>Me everytime when my friends ask me to join cricket match on weekends
                </span>
                <div className="AboutTextAndImg">
                    <img src={ammanahi} alt="" className="AboutImg1" />
                </div>
            </div>
        </section>
    )
}
export default SkillSet;