import React from "react";
import './App.css';
import DocumentViewer from "./Components/DocumentViewer";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import MainContent from "./Components/MainContent";
import ReactGA from "react-ga4";

const usePageViews = () => {
  const location = useLocation();

  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);
};

const PageViewTracker = () => {
  usePageViews();
  return null;
};
function App() {
  ReactGA.initialize("G-5KYGX0NZLC");

  return (
    <div className="app">
      <BrowserRouter>
        <PageViewTracker />
        <Routes>
          <Route path="/" element={<MainContent />} />
          <Route path="/DocumentViewer" element={<DocumentViewer />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
