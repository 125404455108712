import React from "react";
import './Education.css';
import msdhoni from '../assests/msdhoni.png';
import tenth from '../assests/tenth.png';
import btech from '../assests/btech.png';
import firsthome from '../assests/firsthome.png';
import mybtech from '../assests/mybtech.png';
const Education = () => {
    return (
        <section id="About3">
            <div className="aboutsubdiv">
                <span>Then in 9th class even I had MS Dhoni moment
                He has chosen the cricket , I decided to give up cricket and pursue education seriously
                </span>
                <div className="AboutTextAndImg">
                    <img src={msdhoni} alt="" className="AboutImg1" />
                </div>
                <span>This is me in 10th and 12th </span>
                <div className="AboutTextAndImg">
                    <img src={tenth} alt="" className="AboutImg1" />
                </div>
                <span>Then joined the college which is mother and father of discipline and known for its strict rules </span>
                <div className="AboutTextAndImg">
                    <img src={btech} alt="" className="AboutImg1" />
                </div>
                <span>But going away from home for the first time </span>
                <div className="AboutTextAndImg">
                    <img src={firsthome} alt="" className="AboutImg1" />
                </div>
                <span>     My Btech </span>
                <div className="AboutTextAndImg">
                    <img src={mybtech} alt="" className="AboutImg1" />
                </div>
               
            </div>
        </section>
    )
}
export default Education;