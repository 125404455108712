import React from "react";
import btechmba from '../assests/btechmba.png';
import apunko from '../assests/apunko.png';
import alliswell from '../assests/alliswell.png';

const AfterMba = () => {
    return (
        <>
        <section id="About4">
            <div className="aboutsubdiv">
            <span>Then I want to do something different</span>
                <div className="AboutTextAndImg">
                    <img src={apunko} alt="" className="AboutImg1" />
                </div>
                <span>Then joined MBA<br />Right from first idea I used to tell myself
                </span>
                <div className="AboutTextAndImg">
                    <img src={alliswell} alt="" className="AboutImg1" />
                </div>
                <span>How I have changed given the strict dress code </span>
                <div className="AboutTextAndImg">
                    <img src={btechmba} alt="" className="AboutImg1" />
                </div>
            </div>
        </section>
        </>
    )
}

export default AfterMba;