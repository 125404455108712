import React from "react";
import './Home.css';
import Profile from '../assests/profilepic (2).jpeg';
import Button from 'react-bootstrap/Button';
import ButtonContainer from "./ButtonContainer";
import DocButton from "./DocButton";
import About from "./About";
import SkillSet from "./SkillSet";
import Education from "./Education";
import WorkExperience from "./Work Experience";
import AfterMba from "./AfterMba";
import Personality from "./Personality";
import BeforeWorking from "./BeforeWorking";

const Home = () => {
    return (
        <><section id="Home">
            <div className="subdiv">
                <div className="info">
                    <img src={Profile} alt="Profile" className="profile" />
                    <div className="num">
                        <p className="text2">6</p>
                        <p className="text">yrsExp</p>
                    </div>
                    <div className="num">
                        <p className="text2">2014</p>
                        <p className="text">B.Tech</p>
                    </div>
                    <div className="num">
                        <p className="text2">2018</p>
                        <p className="text">MBA</p>
                    </div>
                </div>
                <div>
                    <h1 className="h1">Kiran Naru <img src="https://em-content.zobj.net/source/telegram/386/flag-india_1f1ee-1f1f3.webp" alt="Indian Flag" class="flag" /></h1>
                    <p className="desccription">0-1 Guy<br />
                        Hopeless Entrepreneur(read
                        like hopeless Romantic) <br />
                        More of a Generalist than a Specialist <br />
                        Sasta Virat Kohli <br />
                        Day Dreamer</p>
                </div>
                <ButtonContainer />
            </div>
        </section>
            {/* <About />
            <SkillSet />
            <Education />
            <AfterMba />
            <BeforeWorking />
            <WorkExperience />
            <Personality />
            <DocButton /> */}
        </>
    );
}

export default Home;
