import React from "react";
import presentation from '../assests/presentation.png';
import ProjectDead from '../assests/projectdeadline.png';
import video from "../assests/prabhasspeech.mp4";
import VideoRender from "./VideoPlayer";
import lastminutepresentation from '../assests/lastminutepresentation.png';
import Outings from '../assests/outings.png';
import twoyearsmaba from '../assests/twoyearsmba.png';
const BeforeWorking = ({ currentPlaying, onVideoPlay }) => {
    return(
        <>
        <section id="About5">
            <div className="aboutsubdiv">
            <span>How my initial presentation would look like
                </span>
                {/* <VideoRender url={video} /> */}
                <VideoRender 
                    url={video} 
                    isPlaying={currentPlaying === 'video'} 
                    onPlay={() => onVideoPlay('video')} 
                />
                <div className="AboutTextAndImg">
                    <img src={ProjectDead} alt="" className="AboutImg1" />
                </div>
                <div className="AboutTextAndImg">
                    <img src={presentation} alt="" className="AboutImg1" />
                </div>
                <div className="AboutTextAndImg">
                    <img src={lastminutepresentation} alt="" className="AboutImg1" />
                </div>
                <span>Parties and Outings</span>
                <div className="AboutTextAndImg">
                    <img src={Outings} alt="" className="AboutImg1" />
                </div>
                <span>  How my 2 years MBA is like
                </span>
                <div className="AboutTextAndImg">
                    <img src={twoyearsmaba} alt="" className="AboutImg1" />
                </div>
</div>
        </section>
        </>
    )
}

export default BeforeWorking;