import React from "react";
import './About.css';
import Picture from '../assests/Picture.png';
import picture2 from '../assests/Picture2.png';
import picture3 from '../assests/Picture3.png';
import picture4 from '../assests/Picture4.jpeg';
import picture5 from '../assests/Picture5.png';
import subject from '../assests/subject.png';

const About = () => {
  return (

    <section id="About1">
      <div className="aboutsubdiv">
        <h1 className="abt">ABOUT</h1>
        <div className="AboutsImgs">
          <span style={{color: 'black', fontWeight: 'bold', textAlign: 'center'}}>April 28th ,1992 - Some 5391XXXXXXXth person to say "Hello World"</span>
          <div className="AboutTextAndImg">
            <img src={Picture} alt="Picture" className="AboutImg" />
          </div>
          <span>Growing up, this is how my room used to be, full of cricket posters.</span>
          <div className="AboutTextAndImg">
            <img src={picture2} alt="Picture2" className="AboutImg1" />
          </div>
          <span>Only Motivation to go to school</span>
          <div className="AboutTextAndImgs">
            <img src={picture3} alt="Picture3" className="AboutImg2" />
            <img src={picture4} alt="Picture4" className="AboutImg2" />
          </div>
          <div className="AboutTextAndImg">
            <img src={picture5} alt="Picture5" className="AboutImg1" />
          </div>
          <span>But somehow I am also more fascinated towards maths and used to score really well
                </span>
                <div className="AboutTextAndImg">
                    <img src={subject} alt="" className="AboutImg1" />
                </div>
        </div>
      </div>
    </section>

  )
}

export default About;
