import React, { useState } from 'react';
import Home from "./Home";
import About from "./About";
import SkillSet from "./SkillSet";
import Education from "./Education";
import AfterMba from "./AfterMba";
import BeforeWorking from "./BeforeWorking";
import WorkExperience from "./Work Experience";
import Personality from "./Personality";
import DocButton from "./DocButton";

const MainContent = () => {
    const [currentPlaying, setCurrentPlaying] = useState(null);

    const handleVideoPlay = (videoId) => {
        setCurrentPlaying(videoId);  // Set the currently playing video
    };

    return (
        <>
            <Home />
            <About />
            <SkillSet currentPlaying={currentPlaying} onVideoPlay={handleVideoPlay} />
            <Education />
            <AfterMba />
            <BeforeWorking currentPlaying={currentPlaying} onVideoPlay={handleVideoPlay} />
            <WorkExperience />
            <Personality currentPlaying={currentPlaying} onVideoPlay={handleVideoPlay} />
            {/* <DocButton /> */}
        </>
    );
}

export default MainContent;
