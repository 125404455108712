import React from "react";
import VideoRender from "./VideoPlayer";
import Introvert from '../assests/introvert.png';
import cricket from '../assests/cricket.mp4';
const Personality = ({ currentPlaying, onVideoPlay }) => {
    return (
        <>
            <section id="PersonalityAbout">
                <div className="aboutsubdiv">
                    <span>
                        About my personality <br />
                        As per the Myers-Briggs Type indicator i am an ISFP
                    </span>
                    <div className="AboutTextAndImg">
                        <img src={Introvert} alt="" className="AboutImg1" />
                    </div>
                    <span>An occasional cricketer</span>
                    {/* <VideoRender url={cricket} /> */}
                    <VideoRender 
                    url={cricket} 
                    isPlaying={currentPlaying === 'cricket'} 
                    onPlay={() => onVideoPlay('cricket')} 
                />
                </div>
            </section>
        </>
    )
}

export default Personality;