// import React from 'react';
// import ReactPlayer from 'react-player';

// const VideoRender = ({ url }) => {
//   return (
//     <div className="video-container">
//       <ReactPlayer
//         url={url}
//         playing
//         loop
//         className="reactplayer"
//         width="100%"
//         controls
//       />
//     </div>
//   );
// };

// export default VideoRender;

import React from 'react';
import ReactGA from "react-ga4";
import ReactPlayer from 'react-player';

const VideoRender = ({ url, isPlaying, onPlay }) => {
  // const handlePlay = () => {
  //   if (onPlay) {
  //     onPlay();  // Notify parent that this video is playing
  //   }
  // };
  const handlePlay = () => {
    ReactGA.event({
      category: 'Video',
      action: 'Play',
      label: url
    });
    if (onPlay) {
      onPlay();  // Notify parent if needed
    }
  };

  return (
    <div className="video-container">
      <ReactPlayer
        url={url}
        playing={isPlaying}
        loop
        className="reactplayer"
        width="100%"
        controls
        onPlay={handlePlay}
      />
    </div>
  );
};

export default VideoRender;
