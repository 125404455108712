import React from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const DocButton = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        // Track button click event
        ReactGA.event({
          category: 'User',
          action: 'Click',
          label: 'Resume Button'
        });
    
        navigate('/DocumentViewer');
    };

    // Check if the device is iOS
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    return (
        <>
            {isIOS && (
                <section id="resumecontainerAbout">
                    <div className="resumecontainer">
                        <button className="resume" onClick={handleButtonClick}>
                            Enough of memes, Click here for a little more professional CV
                        </button>
                    </div>
                </section>
            )}
        </>
    );
};

export default DocButton;
