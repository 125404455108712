import React from "react";
import './WorkExperience.css';
import Zomato from '../assests/zomato.png';
import workingstartup from '../assests/workingstartup.png';
import uddaan from '../assests/uddaan.png';
import byjus from '../assests/byjus.png';
import leavingudaan from '../assests/leavingudaan.png';
import fieldvisit from '../assests/fieldvisit.png';
import mymanager from '../assests/mymanager.png';

const WorkExperience = () => {
    return (
        <section id="About">
            <div className="aboutsubdiv">
                <span>Working in startups</span>
                <div className="AboutTextAndImg">
                    <img src={workingstartup} alt="" className="AboutImg1" />
                </div>
                <span>Performance in zomato</span>
                <div className="AboutTextAndImg">
                    <img src={Zomato} alt="" className="AboutImg1" />
                </div>
                <span>My manager to me every month</span>
                <div className="AboutTextAndImg">
                    <img src={mymanager} alt="" className="AboutImg1" />
                </div>
                <span>Joined Udaan after zomato</span>
                <div className="AboutTextAndImg">
                    <img src={uddaan} alt="" className="AboutImg1" />
                </div>
                <span>Me during the field visits</span>
                <div className="AboutTextAndImg">
                    <img src={fieldvisit} alt="" className="AboutImg1" />
                </div>
                <span>My feeling after leaving udaan</span>
                <div className="AboutTextAndImg">
                    <img src={leavingudaan} alt="" className="AboutImg1" />
                </div>
                <span>Joined BYJUs strategy, only to realise it has lot to do with data analysis and little strategy</span>
                <div className="AboutTextAndImg">
                    <img src={byjus} alt="" className="AboutImg1" />
                </div>
                
            </div>
        </section>
    )
}
export default WorkExperience;