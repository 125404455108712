import React, { useState } from "react";
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

function DocumentViewer() {
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    console.log('numPages', numPages);
    setNumPages(numPages);
  };

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <>
      {/* <div className="docview">
        <Document
          file='/resume.pdf'
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(
            new Array(numPages),
            (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1} />
            )
          )}
        </Document>
      </div> */}
      <div className="pdf-container">
        {isMobile ? (
          <p>
            <a href="/resume.pdf" target="_blank" rel="noopener noreferrer">
              Click here once more for CV
            </a>
          </p>
        ) : (
          <iframe
            src="/resume.pdf"
            title="Resume"
            width="100%"
            height="100%"
            style={{ border: "none", height: "100vh" }}
          ></iframe>
        )}
      </div>
    </>
  );
}

export default DocumentViewer;
